import React from "react";
import thumb2 from "../Assets/thumb2.png";
import emoji from "../Assets/emoji.png";
import underline from "../Assets/underline.png";
import warrow from "../Assets/white_arrow.png";
import { motion as m } from "framer-motion";

const WhyUs = ({ video, videoLink }) => {
  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.5, delayChildren: 0.3, duration: 0.75 },
    },
  };

  const child = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1 },
    },
    hidden: {
      opacity: 0,
      y: 150,
      transition: { duration: 1 },
    },
  };
  return (
    <div className="padding-section relative z-[2]" id="about">
      <div
        className="hidden sm:flex absolute top-0 left-0 bg-cover bg-center w-[100%] h-[100%] z-[-1] opacity-[0.66]"
        style={{ backgroundImage: `url('images/bg.png')` }}
      />
      <div
        className="sm:hidden absolute top-0 left-0 bg-cover bg-center w-[100%] h-[100%] z-[-1] opacity-[0.66]"
        style={{ backgroundImage: `url('images/bg_mobile_1.png')` }}
      />
      <div className="hidden sm:flex z-[1]">
        <img
          src={warrow}
          alt=""
          className="h-[40vh] absolute z-[0] left-[45vw] top-[5vh]"
        />
      </div>

      <div className="flex flex-col sm:flex-row-reverse gap-[40px] sm:gap-0 sm:items-center sm:justify-between sm:h-[80vh] z-[0] ">
        <m.img
          src={thumb2}
          alt=""
          className="w-[100vw] sm:w-[30vw] mt-[10px] rounded-[5px] cursor-pointer"
          // onClick={() => {
          //   video(true);
          //   videoLink("l_suclYUL9Y");
          // }}
          initial={{ opacity: 0, scale: 0.2 }}
          whileInView={{ opacity: 1, scale: 1 }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 1.1 }}
          whileFocus={{ scale: 1.1 }}
          transition={{ duration: 0.75 }}
          viewport={{ once: true }}
        />
        <m.div
          className="flex flex-col gap-[25px] font-roboto font-semibold"
          variants={container}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <m.div
            className="flex flex-col sm:mb-[4vh]"
            variants={child}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <div className="flex sm:overflow-visible  sm:h-[7vh]">
              <span className=" text-[30px] leading-[35px] text-white semi-heading-roboto">
                Why Us
              </span>
              <img
                src={emoji}
                alt=""
                className="h-[5vh] sm:h-[7vh] sm:ml-[13px] ml-[8px]"
              />
            </div>
            <img src={underline} alt="" className="sm:w-[20%] w-[35%]" />
          </m.div>
          <m.div
            className="text-[15px] leading-[28px] text-white/[77%] border-l-2 border-white/[86%] pl-[20px] body-1 sm:w-[40vw]"
            variants={child}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <p className="mb-[10px] sm:mb-[20px]">
              We are a team of skilled rappers, music producers, writers and
              video editors. We have worked with professional music labels and
              are experienced artists.
            </p>
            <p>
              Being a rapper, the Founder of RapAdds B Venkatesh aka VENMUS is
              also a developer who understands technology and its aspects which
              makes it easy for us to create good music with top-notch lyrics as
              compared to other commercial artists.
            </p>
          </m.div>
        </m.div>
      </div>
    </div>
  );
};
export default WhyUs;
