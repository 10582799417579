import React from "react";
import yarrow from "../Assets/Yellow_arrow.png";
import barrow from "../Assets/black_arrow.png";
import thumb3 from "../Assets/thumb3.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { motion as m } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import AnimatedText from "./AnimatedText";
import AnimatedWord from "./AnimatedWord";

const Home = ({ video, videoLink }) => (
  <div
    className="mb-[8vh] mt-[8vh] sm:mt-[14vh] sm:mb-[0vh] sm:h-[87vh] padding-section"
    id="homepage"
  >
    <div className="flex flex-col items-center sm:flex sm:flex-row sm:justify-center sm:align-middle z-[1]">
      <div className="sm:hidden relative w-[75vw] z-[0]">
        <div className="yellow-glow-mobile absolute top-0 left-0 h-[100%] w-[100%] z-[-1]" />
        <m.img
          initial={{ opacity: 0, scale: 0.5 }}
          whileInView={{ opacity: 0.8, scale: 1 }}
          onTap={{
            scale: 1.2,
          }}
          whileTap={{ scale: 1.2 }}
          transition={{ duration: 0.75 }}
          viewport={{ once: true }}
          src={thumb3}
          alt="Video"
          className="asbsolute top-0 h-[100%] w-[100%] cursor-pointer rounded-[10px]"
          onClick={() => {
            video(true);
            videoLink("5a10zzqm3LA");
          }}
        />
      </div>
      <div className="flex-col mt-[4vh] sm:mt-[0vh] sm:max-w-[50vw]">
        <div className="flex-col mb-[10px] h-auto sm:mb-[10px] sm:mt-[20px] sm:overflow-y-visible">
          <div>
            <AnimatedText
              class='className="font-lovelo will-change-transform will-change-opacity font-black text-[46px] tracking-[0.1em] lovelo-110 yellow-text sm:tracking-[0.18em] w-[60vw] mb-[10px]"'
              text="UNIQUE"
            />
          </div>
          <div class="flex">
            <AnimatedWord
              text="IN ITS OWN WAY"
              class='className="flex items-center will-change-transform will-change-opacity font-lovelo font-black lovelo-48 text-[20px] leading-[36.24px] text-white tracking-[0.01em] w-[60vw]"'
            />
            <m.img
              src={yarrow}
              alt=""
              className="w-[22px] h-[23px] ml-2 mt-[0.4rem] sm:mt-0 sm:w-[50px] sm:h-[60px] sm:ml-7"
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
              viewport={{ once: true }}
            />
          </div>
        </div>
        <div>
          <AnimatedWord
            text="Lets get rid of the old-school marketing techniques of repetitive and boring posters, banners and sometimes high-budget animated videos which also lacked engagement."
            class='className="font-roboto font-normal text-[16px] leading-[26.3px] w-[66vw] sm:text-[24px] sm:leading-[39.46px] sm:w-[33vw] text-white/[77%] sm:mt-[30px] "'
          />
        </div>
        {/* <m.button
          className="hidden sm:flex justify-center  items-center rounded-[30px] yellow-theme w-[11vw] h-[7vh] sm:w-[10vw] sm:h-[7vh] font-poppins font-semibold text-[20px] leading-[30px] tracking-[0.05em] mt-[30px] hover:bg-[#17181F] hover:text-[#fbd700] hover:border-[#fbd700] hover:border-[1px] "
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.7, delay: 1 }}
          viewport={{ once: true }}
        >
          <span>Explore</span>
          <img src={barrow} alt="" className="w-[24px] h-[24px] ml-[3px]" />
        </m.button> */}
      </div>
      <div className="hidden sm:block sm:mt-8 relative w-[100vw] sm:w-[31vw] z-[0]">
        <div className="yellow-glow absolute top-0 left-0 h-[100%] w-[100%] z-[-1]" />
        <m.img
          onTap={{
            scale: 1.5,
          }}
          src={thumb3}
          alt="Video"
          whileHover={{ scale: 1.1, opacity: 1 }}
          className="asbsolute top-0 h-[100%] w-[100%] cursor-pointer rounded-[10px]"
          // onClick={() => {
          //   video(true);
          //   videoLink("5a10zzqm3LA");
          // }}
          initial={{ opacity: 0, scale: 0.5 }}
          whileInView={{ opacity: 0.8, scale: 1 }}
          transition={{ duration: 0.75 }}
          viewport={{ once: true }}
        />
      </div>
    </div>
    <div></div>
    {/* </Carousel> */}
  </div>
);

export default Home;
