import "./App.css";
import { motion as m } from "framer-motion";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import Home2 from "./Components/Home2";
import Home3 from "./Components/Home3";
import Cake from "./Components/Cake";
import WhyUs from "./Components/Whyus";
import Packages from "./Components/Packages";
import Team from "./Components/Team";
import Team2 from "./Components/Team2";
import Team3 from "./Components/Team3";
import Testimonials from "./Components/Testimonials";
import Faq from "./Components/Faq";
import Footer from "./Components/Footer";
import VideoModal from "./Components/VideoPopup";
import React, { useEffect, useState } from "react";
import whatsapp from "./Assets/whatsapp.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

function App() {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const pagination = {
    clickable: true,
  };

  return (
    <div className="App black-theme overflow-hidden">
      <Navbar />
      {showVideoModal && (
        <VideoModal closefn={setShowVideoModal} linkString={videoLink} />
      )}
      <Swiper pagination={pagination} modules={[Pagination]}>
        <SwiperSlide>
          <Home video={setShowVideoModal} videoLink={setVideoLink} />
        </SwiperSlide>
        <SwiperSlide>
          <Home2 video={setShowVideoModal} videoLink={setVideoLink} />
        </SwiperSlide>
        <SwiperSlide>
          <Home3 video={setShowVideoModal} videoLink={setVideoLink} />
        </SwiperSlide>
      </Swiper>
      <Cake />
      <WhyUs video={setShowVideoModal} videoLink={setVideoLink} />
      <Packages />
      <Swiper pagination={pagination} modules={[Pagination]}>
        <SwiperSlide>
          <Team />
        </SwiperSlide>
        <SwiperSlide>
          <Team2 />
        </SwiperSlide>
        <SwiperSlide>
          <Team3 />
        </SwiperSlide>
      </Swiper>
      {/* Will be added after sometime */}
      {/* <Testimonials /> */}
      <Faq />
      <Footer />
      <a href="https://wa.me/+918586965421" rel="noreferrer" target="_blank">
        <m.div
          className="fixed bottom-[15px] right-[20px] sm:bottom-[30px] sm:right-[35px] bg-green-600 rounded-full px-[14px] sm:px-[16px] h-[7vh] sm:h-[7vh] z-10 flex items-center justify-center text-center font-semibold  text-white font-poppins hover:cursor-pointer hover:bg-green-700 "
          initial={{ opacity: 0, scale: 0.5 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.75, delay: 1 }}
          viewport={{ once: true }}
        >
          <img
            src={whatsapp}
            alt="whatsapp icon"
            className="h-[3vh] mr-[2vw] sm:mr-[0.70vw]"
          />
          <span className="whitespace-nowrap">Contact Us</span>
        </m.div>
      </a>
    </div>
  );
}

export default App;
