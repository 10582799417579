import React from "react";
import emojis from "../Assets/emoji_star.png";
import open from "../Assets/quote_open.png";
import close from "../Assets/quote_close.png";
import Carousel from "react-multi-carousel";
import underline from "../Assets/underline.png";
import "react-multi-carousel/lib/styles.css";
import { motion as m } from "framer-motion";

function Testimonials() {
  const data = [
    {
      id: 1,
      name: "Lakshita Sethi",
      review:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel et vitae vehicula ",
    },
    {
      id: 2,
      name: "Lakshita Sethi",
      review:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel et vitae vehicula ",
    },
    {
      id: 3,
      name: "Lakshita Sethi",
      review:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel et vitae vehicula ",
    },
    {
      id: 4,
      name: "Lakshita Sethi",
      review:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel et vitae vehicula ",
    },
    {
      id: 5,
      name: "Lakshita Sethi",
      review:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel et vitae vehicula ",
    },
  ];

  // const container = {
  //   visible: {
  //     opacity: 1,
  //     x: 0,
  //     scale: 1,
  //     transition: { duration: 1 },
  //   },
  //   hidden: {
  //     opacity: 0,
  //     x: 150,
  //     scale: 0.5,
  //     transition: { duration: 1 },
  //   },
  // };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4.2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2.1,
    },
  };

  return (
    <div
      className="flex flex-col sm:gap-[56px] h-[60vh] sm:h-[90vh] bg-[#1E1F26] relative z-[1]"
      id="testimonials"
    >
      <div className="flex flex-col font-roboto font-semibold">
        <m.div
          className="flex flex-col p-[21px_10px_25px_42px] sm:p-[42px_20px_50px_104px] overflow-visible sm:mb-[0vh] sm:mt-[0vh] mb-[2vh] mt-[3vh]"
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <div className="flex align-middle sm:mb-0 h-[5vh]">
            <span className=" text-[30px] leading-[35px] text-white semi-heading-roboto">
              Testimonials
            </span>
            <img
              src={emojis}
              alt=""
              className="w-[10vw] h-[5vh] sm:w-[4vw] sm:h-[8vh] ml-[8px]"
            />
          </div>
          <img
            src={underline}
            alt=""
            className="sm:w-[25%] w-[55%] h-[4vh] sm:h-auto"
          />
        </m.div>
        <div
          // variants={container}
          // initial="hidden"
          // whileInView="visible"
          // transition={{ duration: 1 }}
          // viewport={{ once: true }}
          
        >
          <Carousel
            responsive={responsive}
            ssr
            itemClass="image-item"
            className="gap-[15vw]"
          >
            {data.map((person) => {
              return (
                <div className="w-[45vw] sm:w-[20vw] overflow-x-visible">
                  <div
                    className={`flex flex-col h-[30vh] sm:h-[auto] bg-[#17181F] rounded-[10px] justify-center items-center gap-[1vh] sm:py-[10vh]`}
                  >
                    <img
                      src={open}
                      alt="qoute_open"
                      className="w-[7vw] h-[3vh] sm:w-[3vw] sm:h-[5vh]"
                    />
                    <p className="font-roboto font-normal text-[16px] leading-[21px] text-[#FFFFFF] w-[28vw] sm:w-[13vw] body-4-sm">
                      {person.review}
                    </p>
                    <img
                      src={close}
                      alt="quote_close"
                      className="w-[7vw] h-[3vh] sm:w-[3vw] sm:h-[5vh]"
                    />
                  </div>
                  <div className="font-roboto font-normal text-[16px] leading-[19px] text-[#FFFFFF] mt-[10px]">
                    {person.name}
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
