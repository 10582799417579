import React from "react";
import cake from "../Assets/cake.png";
import cherry from "../Assets/cherry.png";
import cake_bg from "../Assets/cake_bg.png";
import cake_bg_mb from "../Assets/cake_bg_mb.png";
import { motion as m } from "framer-motion";

const Cake = () => {
  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.3, delay: 0.3, duration: 1 },
    },
  };

  const child = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.75 },
    },
    hidden: {
      opacity: 0,
      y: -150,
      transition: { duration: 0.75 },
    },
  };
  return (
    <div className="flex-col sm:h-[75vh] flex sm:flex-row sm:justify-between bg-[#1E1F26] padding-section overflow-y-hidden relative z-[1]">
      <img
        src={cake_bg}
        alt=""
        className="hidden sm:block z-[-1] absolute sm:h-[60vh] right-0 top-[0px]"
      />
      <img
        src={cake_bg_mb}
        alt=""
        className="sm:hidden z-[-1] absolute w-[100vw] left-0 bottom-[5vh]"
      />
      <div className="justify-center flex h-auto sm:items-center sm:mt-0 z-[1]">
        <m.img
          src={cake}
          alt=""
          className="w-[18vh] h-auto sm:w-[25vw]"
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
        />
      </div>
      <div className="justify-center items-center flex mt-[20px] mb-[20px] sm:mt-0 z-[1] overflow-x-visible">
        <m.div
          variants={container}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
          className="flex-col flex items-center pl-7 sm:justify-between w-[58vw] sm:w-[34vw]"
        >
          <m.div className="flex flex-row sm:mb-[25px]" variants={child}>
            <span className="font-roboto font-medium text-[30px] leading-[35.76px] tracking-[0.01em] text-white semi-heading-roboto">
              We are<span className="yellow-text"> cherry </span>
              on the cake
              <img
                src={cherry}
                alt=""
                className="h-[4vh]  sm:h-[5vh] ml-[7px]"
              />
            </span>
          </m.div>
          <m.div
            className="flex flex-col font-roboto font-normal text-[16px] leading-[22.3px] text-white/[77%] gap-[20px] mt-[30px] sm:mt-0 roboto-reg sm:justify-between"
            variants={child}
          >
            <span>
              Marketing is a paramount stage for a company, and companies need
              to get rid of old school marketing techniques.{" "}
            </span>
            <span>
              RapAdds can be the cherry on your cake because we produce a rap which will represent your
              company in the most finest way.{" "}
            </span>
          </m.div>
        </m.div>
      </div>
    </div>
  );
};

export default Cake;
