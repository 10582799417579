import React, { useState } from "react";
import { navLinks, navL } from "../Constants";
import logo from "../Assets/logo.png";
import dd from "../Assets/drop_down.png";
import cancel from "../Assets/cancel.png";
import "../App.css";
import { motion as m } from "framer-motion";

function Navbar() {
  const [toggle, setToggle] = useState(true);
  const [isActive, setIsActive] = useState("#homepage");

  return (
    <nav className="flex flex-row w-full pr-4 bg-[#17181f] fixed z-[10000000] justify-between items-center sm:h-[14vh] sm:mr-[52px]">
      <a href="https://linktr.ee/rapadds" rel="noreferrer" target="_blank">
        <img
          src={logo}
          alt="logo"
          className="w-[73px] ml-[0] sm:w-[10vw] sm:mt-[15px]"
        />
      </a>

      <img
        src={toggle ? dd : cancel}
        alt="drop-down"
        className="w-[23px] sm:hidden"
        onClick={() => {
          setToggle((toggle) => !toggle);
        }}
      />

      <div class="hidden sm:flex align-middle font-poppins font-normal text-[24px] leading-[36px] tracking-[0.02em] text-white gap-[60px] justify-center">
        {navL.map((nav, index) => (
          <m.a
            key={nav.id}
            href={nav.id}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 + index * 0.1 }}
            viewport={{ once: true }}
            className={nav.id === isActive ? "text-[#FBD600]" : ""}
            onClick={() => setIsActive(nav.id)}
          >
            {nav.title}
          </m.a>
        ))}
      </div>
      <a
        href="https://drive.google.com/file/d/1pzhQ82yR6lw_9KRNoOWyOYC9XaXiaPaw/view?usp=sharing"
        rel="noreferrer"
        target="_blank"
        className="hidden sm:block mr-4"
      >
        <button
          className="yellow-theme hidden sm:block text-[20px] font-poppins font-semibold leading-[30px] text-[#17181F]
            rounded-[10px] h-[7vh] w-[11vw] hover:bg-[#17181F] hover:text-[#fbd700] hover:border-[#fbd700] hover:border-[1px]"
        >
          Pitch Deck
        </button>
      </a>

      <div
        className={`${
          toggle ? "hidden" : "block"
        } fixed top-[60px] right-0 mb-2 w-full h-full overflow-hidden touch-none md:hidden p-6 black-theme z-[500000]`}
      >
        <ul className="list-none flex justify-evenly items-center flex-col w-full h-[60vh]">
          {navLinks.map((nav, index) => (
            <li
              key={nav.id}
              onClick={() => {
                setIsActive(nav.title);
                setToggle((toggle) => !toggle);
              }}
            >
              <a
                href={
                  nav.title === "PitchDeck"
                    ? "https://drive.google.com/file/d/1pzhQ82yR6lw_9KRNoOWyOYC9XaXiaPaw/view?usp=sharing"
                    : nav.id
                }
                // target={`${nav.title === "pitchdeck" && "_blank"}`}
                className={`font-poppins text-[28px] font-medium
                leading-[42px]
                tracking-[0.02em]
                text-[#FFFFFF]
                 ${isActive === nav.title ? "text-[#FBD500]" : "text-white"}`}
              >
                {nav.title}
              </a>
            </li>
          ))}
          <a
            href={
              "https://drive.google.com/file/d/1pzhQ82yR6lw_9KRNoOWyOYC9XaXiaPaw/view?usp=sharing"
            }
            rel="noreferrer"
            target="_blank"
            className={`font-poppins text-[28px] font-medium
                leading-[42px]
                tracking-[0.02em]
                text-[#FFFFFF]`}
          >
            Pitch Deck
          </a>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
