import React from "react";
import logo from "../Assets/logo.png";
import Twitter from "../Assets/Twitter.png";
import LinkedIn from "../Assets/Linkedin.png";
import Youtube from "../Assets/Youtube.png";
import Instagram from "../Assets/Instagram.png";
import Telegram from "../Assets/Telegram.png";
import Spotify from "../Assets/Spotify.png";

const Footer = () => (
  <div className="padding-section flex flex-col relative z-[1]">
    <div
      className="hidden sm:flex absolute top-0 left-0 bg-cover bg-center w-[100%] h-[100%] z-[-1]"
      style={{ backgroundImage: `url('images/footer.png')` }}
    />
    <div
      className="sm:hidden absolute top-0 left-0 bg-cover bg-center w-[100%] h-[100%] z-[-1]"
      style={{ backgroundImage: `url('images/footer_mb.png')` }}
    />
    <span
      class="
                    font-montserrat
                    font-medium
                    text-[38px]
                    mt-8
                    leading-[27px]
                    text-white/[71%]
                    mb-[50px]
                    sm:mb-[10vh]
                    sm:mt-[3vh]
                    footer-text
                    "
    >
      Let’s Connect.
    </span>
    <div className="sm:flex sm:flex-row-reverse sm:h-[35vh] sm:w-[80vw] sm:justify-between ">
      <div
        class="   font-montserrat
                    font-normal
                    text-[12px]
                    leading-[15px]
                    text-white
                    mt-[30px]
                    h-[50vh]
                    sm:h-auto
                    flex
                    flex-col
                   justify-between
                   border-b-[0.5px]
                   border-white
                   pb-[7vh]
                   sm:flex-row
                   sm:justify-start
                   sm:w-[55vw]
                  "
      >
        <div className="flex sm:flex-col justify-between h-[20vh] sm:gap-[8vh]">
          <div className="heading-sm font-bold">OUR SERVICES</div>
          <div className="flex flex-col justify-between sm:gap-[1vh] caption-med">
            <span>Company Anthem Song</span>
            <span>Product Rap</span>
            <span>Music Themes</span>
            <span>Pitch Day Raps</span>
          </div>
        </div>
        {/* <div className="flex sm:flex-col justify-between h-[20vh] sm:gap-[8vh]">
          <div className="heading-sm">GENERAL</div>
          <div className="flex flex-col justify-between sm:gap-[1vh] caption-med">
            <span>Network visibility</span>
            <span>Network</span>
            <span>Network visibility</span>
            <span>Network</span>
            <span>Network visibility</span>
          </div>
        </div> */}
        <div className="flex sm:flex-col justify-between h-[10vh] sm:gap-[8vh] sm:ml-[10rem]">
          <div className="heading-sm font-bold">SOCIAL</div>
          <div className="flex flex-col justify-between sm:gap-[1vh] caption-med">
            <div className="grid grid-flow-row grid-cols-3 gap-4">
              <a
                href="https://twitter.com/rapadds"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={Twitter}
                  alt=""
                  className="w-[10w] h-[5vh] sm:w-[9w] sm:h-[6vh]"
                />
              </a>

              <a
                href="https://www.linkedin.com/company/rapadds"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={LinkedIn}
                  alt=""
                  className="w-[10w] h-[5vh] sm:w-[9w] sm:h-[6vh]"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UC-D45Yb67z_MGLvth8t_EBA"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={Youtube}
                  alt=""
                  className="w-[10w] h-[5vh] sm:w-[9w] sm:h-[6vh]"
                />
              </a>
            </div>
            <div className="grid grid-flow-row grid-cols-3 gap-4 mt-4">
            <a
                href="https://www.instagram.com/_venmus_/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={Instagram}
                  alt=""
                  className="w-[10w] h-[5vh] sm:w-[9w] sm:h-[6vh]"
                />
              </a>
              <a
                href="https://t.me/+e3DKqnuRWMI2NjA9"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={Telegram}
                  alt=""
                  className="w-[10w] h-[5vh] sm:w-[9w] sm:h-[6vh]"
                />
              </a>
              <a
                href="https://open.spotify.com/artist/4fz1mgqD3rwoAyV5IR1aTU"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={Spotify}
                  alt=""
                  className="w-[10w] h-[5vh] sm:w-[9w] sm:h-[6vh]"
                />
              </a>
            </div>
            {/* <span>Network visibility</span>
            <span>Network</span>
            <span>Network visibility</span> */}
          </div>
        </div>
      </div>
      <div className="mt-[20px] flex flex-col items-start sm:w-[13vw]">
        <img src={logo} alt="" className="w-[18vw] sm:w-[8vw]" />
        <h3
          className="font-montserrat
                    font-bold
                    text-[20px]
                    leading-[24px]
                    sm:text-[30px]
                    sm:leading-[36.5px]
                    text-white
                    tracking-[0.075em]"
        >
          RapAdds
        </h3>
        <p
          className="font-montserrat
                   font-normal
                    text-[10px]
                    leading-[15px]
                    text-white
                    w-[80vw]
                    mt-[25px]
                    sm:w-[17vw]
                    body-5"
        >
          <span className="font-bold">Phone:</span> +918586965421
          <br></br>
          <span className="font-bold">Email:</span> rapaddsorg@gmail.com
        </p>
      </div>
    </div>
  </div>
);

export default Footer;
