import React, { useState } from "react";
import emojig from "../Assets/emoji_girl.png";
import expand from "../Assets/drop_down_1.png";
import minus from "../Assets/minus.png";
import triangle from "../Assets/triangle.png";
import { motion as m, AnimatePresence } from "framer-motion";

const Disclosure = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`flex flex-col w-full p-3 flex justify-between border-b-[1px] border-white/[50%] pb-[15px]`}
      onClick={() => setIsOpen((prev) => !prev)}
    >
      <button
        aria-controls={props.title}
        aria-expanded={isOpen}
        className={`flex justify-between text-left items-center w-full space-x-4`}
      >
        <div
          className="font-montserrat
                    font-normal
                    text-white
                    text-[12px]
                    sm:text-[28px]"
        >
          {props.title}
        </div>

        <AnimatePresence initial={false} mode="wait">
          <m.div
            key={isOpen ? "minus" : "plus"}
            initial={{
              rotate: isOpen ? -90 : 90,
            }}
            animate={{
              rotate: 0,
              transition: {
                type: "tween",
                duration: 0.15,
                ease: "circOut",
              },
            }}
            exit={{
              rotate: isOpen ? -90 : 90,
              transition: {
                type: "tween",
                duration: 0.15,
                ease: "circIn",
              },
            }}
          >
            {isOpen ? (
              <img
                src={minus}
                alt="minus"
                class="w-[16px] sm:w-[2vw] cursor-pointer"
              />
            ) : (
              <img
                src={expand}
                alt="plus"
                class="w-[16px]  sm:w-[2vw] cursor-pointer"
              />
            )}
          </m.div>
        </AnimatePresence>
      </button>

      <m.div
        id={props.title}
        initial={false}
        animate={
          isOpen
            ? {
                height: "auto",
                opacity: 1,
                marginTop: "15px",
                display: "block",
                transition: {
                  height: {
                    duration: 0.4,
                  },

                  opacity: {
                    duration: 0.25,
                    delay: 0.15,
                  },
                },
              }
            : {
                height: 0,
                opacity: 0,
                transition: {
                  height: {
                    duration: 0.4,
                  },
                  opacity: {
                    duration: 0.25,
                  },
                },
                transitionEnd: {
                  display: "none",
                },
              }
        }
        className="font-montserrat
        font-normal
        sm:text-[20px]
        text-[10px]
        text-white
        "
      >
        {props.body}
      </m.div>
    </div>
  );
};

function Faq() {
  const data = [
    {
      id: "1",
      question: "Does RapAdds only make raps?",
      answer: "RapAdds not only makes rap but also produces music, intros and outros for content creators and a lot more.",
    },
    {
      id: "2",
      question: "Do the clients have to pay the full amount when making a deal?",
      answer: "The clients have to first pay an advance of 40% of the total charge before the initiation of the project and the rest when the final ouput is received by the client",
    },
    {
      id: "3",
      question: "Why RapAdds and not any other commercial music label?",
      answer: "RapAdds charge way lesser than the commercial ones and we have rappers who are aligned towards technology which is in advantage for a better result at the end.",
    },
    {
      id: "4",
      question: "What if I am not happy with the product/rap?",
      answer: "The advance would be refunded to the client within a week.",
    },
  ];

  return (
    <div
      className="padding-section flex flex-col gap-[56px] mb-[20px] sm:mb-[80px] relative z-0 overflow-visible"
      id="faq"
    >
      <img
        src={triangle}
        alt=""
        className="z-[-1] absolute hidden sm:block sm:h-[130vh] left-0 top-[-50px]"
      />
      <div className="flex flex-col font-montserrat font-semibold">
        <m.div
          className="flex items-center justify-center h-[7vh] sm:mt-[5vh]"
          initial={{ opacity: 0, y: 80 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <span className=" text-[20px] leading-[29px] text-white heading-mst">
            Got questions?
          </span>
          {/* <div className="w-[20vw] sm:w-[10vw] ml-[0px] pl-[0px] overflow-hidden sm:hidden">
            <img src={emojig} alt="" />
          </div> */}
        </m.div>
        <m.p
          className="font-montserrat font-normal text-[8px] leading-[12px] text-center text-white/[66%] sm:font-medium sm:text-[24px] sm:leading-[35px] sm:mt-[25px]"
          initial={{ opacity: 0, y: 80 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          Frequently asked questions by people
        </m.p>

        <div className="flex flex-col w-full justify-center items-center space-y-7 sm:mt-[50px] mt-[10px]">
          {data.map((faq, i) => (
            <Disclosure key={i} title={faq.question} body={faq.answer} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Faq;
