export const navLinks = [
  {
    title: "Home",
    id: "#homepage",
  },
  {
    title: "About Us",
    id: "#about",
  },
  {
    title: "Packages",
    id: "#packages",
  },
  {
    title: "Team",
    id: "#team",
  },
  {
    title: "FAQ",
    id: "#faq",
  },
];


export const navL = [
  {
    title: "Home",
    id: "#homepage",
  },
  {
    title: "About Us",
    id: "#about",
  },
  {
    title: "Packages",
    id: "#packages",
  },
  {
    title: "Team",
    id: "#team",
  },
];
