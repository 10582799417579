import React from "react";
import emoji2 from "../Assets/emoji2.png";
import triangle_1 from "../Assets/triangle_1.png";
import triangle_mb from "../Assets/triangle_mb.png";
import underline from "../Assets/underline.png";
import dot_box from "../Assets/dot_box.png";
import { motion as m } from "framer-motion";

const Packages = () => {
  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.4, delayChildren: 0.4, duration: 1.2 },
    },
  };

  const child = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 1 },
    },
    hidden: {
      opacity: 0,
      scale: 0.5,
      transition: { duration: 1 },
    },
  };
  return (
    <div
      className="sm:h-[90vh] sm:mt-[5rem] sm:mb-[5rem] flex flex-col justify-between sm:justify-center padding-section mb-[30px] relative z-[1]"
      id="packages"
    >
      <m.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <div
          alt="yline"
          className="hidden sm:block z-[-1] absolute w-[100vw] h-[0.25vh] left-0 top-[28vw] yline"
        />
        <img
          src={triangle_1}
          alt=""
          className="hidden sm:block z-[-1] absolute right-0 top-[-11vh] w-[21%]"
        />
        <img
          src={dot_box}
          alt=""
          className="z-[-1] absolute left-[-12vw] top-[1vh] w-[60%] sm:left-[1vw] sm:top-[-5vh] sm:w-[27%]"
        />
        <img
          src={dot_box}
          alt=""
          className="z-[0] absolute right-[-12vw] top-[35vh] w-[60%] sm:right-[1vw] sm:top-[37vh] sm:w-[27%]"
        />

        <img
          src={dot_box}
          alt=""
          className="sm:hidden z-[-1] absolute left-[-12vw] top-[58vh] w-[60%] sm:left-[1vw] sm:top-[-5vh] sm:w-[27%]"
        />
        <img
          src={dot_box}
          alt=""
          className="sm:hidden z-[0] absolute right-[-12vw] bottom-[-15vh] w-[60%] sm:right-[1vw] sm:top-[37vh] sm:w-[27%]"
        />

        <img
          src={triangle_mb}
          alt=""
          className="sm:hidden z-[-1] absolute right-0 top-[12vh] h-[140vh]"
        />
      </m.div>

      <div className="vector z-[-10] mr-0 ml-auto" />
      <m.div
        className="flex flex-col mb-[4vh] mt-[3vh] sm:mt-0 overflow-visible"
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <div className="flex items-center z-0 h-[5vh]">
          <span className="font-roboto font-semibold text-[30px] leading-[35.16px] text-white semi-heading-roboto">
            Packages
          </span>
          <img
            src={emoji2}
            alt=""
            className="w-[20vw] h-[15vh] sm:w-[7vw] sm:h-[17vh]"
          />
        </div>
        <img src={underline} alt="" className="sm:w-[20%] w-[45%]" />
      </m.div>
      <m.div
        className="flex flex-col gap-[15vh] sm:gap-0 sm:flex-row sm:justify-around z-0"
        variants={container}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <m.div
          className="bg-[#1E1F26] shadow-[0px_4px_4px_rgba(0,0,0, 0.11)] rounded-[5px] w-[78vw] h-[42vh] px-[8vw] py-[5vw] sm:w-[33vw] sm:h-auto sm:px-[4vw] sm:pt-[10vh] border-[1px] border-[#FBD700] flex flex-col justify-around"
          variants={child}
        >
          <h3 className="font-roboto font-medium text-[22px] sm:text-[32px] sm:mt-4 leading-[26.22px] tracking-[0.01em] yellow-text text-center package-heading">
            Package 1
          </h3>
          <div className="font-roboto font-normal text-[15px] sm:text-[20px] leading-[32px] sm:leading-[60px] sm:mt-8  tracking-[0.01em] text-white/[83%]">
            <span>
              Only <span className="text-yellow-300">audio</span> master file.
              <br />
            </span>
            <span>
              We hold <span className="text-yellow-300">no royalties</span> over the song.
              <br />
            </span>
            <span>
              We assure  <span className="text-yellow-300">no copyright</span> claim is issued.
              <br />{" "}
            </span>
            <span>
              Rap length is of utmost <span className="text-yellow-300">1min 30 sec.</span> <br />
            </span>
          </div>
        </m.div>
        <m.div
          className="bg-[#1E1F26] shadow-[0px_4px_4px_rgba(0,0,0, 0.11)] rounded-[5px] w-[78vw] h-[42vh] px-[8vw] py-[5vw] sm:w-[33vw] sm:h-auto sm:px-[4vw] sm:pt-[10vh] border-[1px] border-[#FBD700] flex flex-col justify-around"
          variants={child}
        >
          <h3 className="font-roboto font-medium text-[22px] sm:text-[32px] sm:mt-4 leading-[26.22px] tracking-[0.01em] yellow-text text-center package-heading">
            Package 2
          </h3>
          <div className="font-roboto font-normal text-[15px] sm:text-[20px] leading-[32px] sm:leading-[60px] sm:mt-8 tracking-[0.01em] text-white/[83%]">
            <span>
            <span className="text-yellow-300">Audio & Video</span> both master file.
              <br />{" "}
            </span>
            <span>
              We hold <span className="text-yellow-300">no royalties</span> over the song.
              <br />{" "}
            </span>
            <span>
              We assure <span className="text-yellow-300">no copyright</span> claim is issued.
              <br />{" "}
            </span>
            <span>
              Rap length is of utmost <span className="text-yellow-300">3 minutes.</span>
              <br />{" "}
            </span>
          </div>
        </m.div>
      </m.div>
    </div>
  );
};
export default Packages;
