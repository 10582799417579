import React from "react";
import emoji1 from "../Assets/emoji1.png";
import emojim from "../Assets/emoji_music.png";
import rimage3 from "../Assets/round_image3.jpeg";
import underline from "../Assets/underline.png";
import { motion as m } from "framer-motion";

const Team = () => {
  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.3, delayChildren: 0.3, duration: 1 },
    },
  };

  const child = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1 },
    },
    hidden: {
      opacity: 0,
      y: -175,
      transition: { duration: 1 },
    },
  };

  const child1 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: 0.75,
    },
    hidden: {
      opacity: 0,
      y: 150,
    },
  };
  return (
    <div
      className="padding-section flex flex-col h-[80vh] gap-[30px] sm:gap-0 sm:h-[80vh] relative z-[1]"
      id="team"
    >
      <div
        className="hidden sm:flex absolute top-0 left-0 bg-cover bg-center w-[100%] h-[80vh] z-[-1]"
        style={{ backgroundImage: `url('images/guitar_bg.png')` }}
      />
      <div
        className="sm:hidden absolute top-0 left-0 bg-cover bg-center w-[100%] h-[100%] z-[-1]"
        style={{ backgroundImage: `url('images/guitar_bg_mb.png')` }}
      />
      <m.div
        className="flex flex-col  overflow-visible sm:mt-[2vh] mt-[3vh]"
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <div className="flex align-middle justify-start sm:h-[7vh] sm:overflow-visible">
          <span className="font-roboto font-semibold text-[30px] leading-[35px] text-white semi-heading-roboto">
            Team
          </span>
          <img
            src={emoji1}
            alt=""
            className="w-[10w] h-[5vh] sm:w-[10w] sm:h-[7vh] sm:ml-[15px] ml-[8px]"
          />
        </div>
        <img src={underline} alt="" className="sm:w-[13%] w-[30%]" />
      </m.div>
      <m.div
        className="flex flex-col sm:flex-row-reverse sm:justify-between sm:items-center"
        variants={container}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <m.div
          className="flex justify-center carousel_1"
          variants={child}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <img
            src={rimage3}
            alt=""
            className="w-[45vw] sm:w-[25vw] mb-[5vh] rounded-[50%]"
          />
        </m.div>
        <m.div
          className="sm:w-[45vw] mb-[5vh]"
          variants={container}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <m.div
            className="flex align-middle justify-start sm:mb-[50px]"
            variants={child1}
          >
            <span className="font-roboto font-bold text-[20px] leading-[24px] sm:font-montserrat sm:font-bold sm:text-[42px] sm:leading-[61px] text-[#27AAE1]">
              Video Editor
            </span>
            <img
              src={emojim}
              alt=""
              className="w-[6vw] h-[4vh] sm:w-[4vw] sm:h-[9vh] ml-[8px]"
            />
          </m.div>
          <m.p
            className="font-roboto font-normal text-[16px] sm:text-[26px] leading-[28px] sm:leading-[40px] text-white/[77%] body-3 mt-[5vh] sm:mt-0"
            variants={child1}
          >
             <a href="https://www.behance.net/Kanav?tracking_source=search_users|Kanav%20Bansal" rel="noreferrer" target="_blank">
              <span className="text-yellow-300 text-[20px] sm:text-[32px] font-bold mr-1 sm:hover:text-[34px] sm:ease-in sm:duration-200">
                MAYAMYTE
              </span>
            </a>{" "}
            Professional video editors, with experience of 2 years. 3D and 2D
            modelling and lyric video editors with experience in freelancing for social media influencers.
          </m.p>
        </m.div>
      </m.div>
    </div>
  );
};
export default Team;
